import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import Nav from "react-bootstrap/Nav"
import "./Chapters.css"

const doDebug = true

function getChapterData() {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark {
        edges {
          node {
            id
            fields {
              slug
              chapter {
                index
                name
              }
              section {
                index
                name
              }
            }
          }
        }
      }
    }
  `)

  let chapters: any[] = []
  let i = 0
  for (const edge of data.allMarkdownRemark.edges) {
    i++
    const slug = edge.node.fields.slug
    const chapter = edge.node.fields.chapter
    const section = edge.node.fields.section
    if (!chapter.index) {
      continue
    }
    let c: any = chapters[chapter.index]
    if (!c) {
      c = {
        index: chapter.index,
        displayIndex: i,
        name: chapter.name.replace(/-|_/g, " "),
        sections: [],
      }
      chapters[chapter.index] = c
    }
    if (!section.index) {
      continue
    }
    let s = c.sections[section.index]
    if (!s) {
      s = c.sections[section.index] = section
    }
    s.slug = slug
  }

  chapters = chapters.filter(c => c !== undefined)

  chapters.forEach((c, index) => {
    c.displayIndex = index + 1
    c.sections = c.sections.filter(s => s !== undefined)
  })

  return chapters
}
export const Chapters = ({ chapterIndex, sectionIndex }) => {
  const chapters = getChapterData()

  doDebug && console.log("chapter", chapterIndex, sectionIndex)
  return (
    <Nav
      defaultActiveKey="/home"
      className="flex-column table-of-contents"
      style={{ fontSize: "0.9rem" }}
    >
      <ul>
        {chapters.map(c => (
          <li key={c.index}>
            <input
              type="checkbox"
              id={"Chapter_" + c.index}
              defaultChecked={c.index === chapterIndex}
            />
            <label htmlFor={"Chapter_" + c.index}>
              {doDebug && <span className="debug"> {c.index} - </span>}
              {c.displayIndex} - {c.name}
            </label>
            <ul>
              {c.sections.map(s => (
                <li key={s.slug}>
                  <input
                    type="checkbox"
                    id={`c${c.index}-s${s.index}`}
                    defaultChecked={
                      c.index === chapterIndex && s.index === sectionIndex
                    }
                  />
                  <label htmlFor={`c${c.index}-s${s.index}`}>
                    {doDebug && <span className="debug"> {s.index}</span>}
                    <Link to={s.slug}>{s.name.replace(/-|_/g, " ")}</Link>
                  </label>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </Nav>
  )
}
