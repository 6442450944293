import React from "react"
import { Row, Col, Container } from "react-bootstrap";
import { Link, graphql } from "gatsby"

import Layout from "../components/Layout"
import { Chapters } from "../components/Chapters"
import { isAuthenticated, hasValidPlanSubscription } from "../services/auth"
import "./BookPage.css";
import { BOOK_PLAN } from "../services/auth2";

const doDebug = true;

export function ReadNext({ to, text }) {

  return (
    <Link to={to} className="read-next">
      <span className="read-next-continue">Continue reading ⇨</span>
      <span className="read-next-title">{text}</span>
    </Link>
  );

}
export function ReadPrev({ to, text }) {

  return (
    <Link to={to} className="read-next">
      <span className="read-next-previous">⇐ Previous</span>
      <span className="read-next-title">{text}</span>
    </Link>
  );

}
export function BookPagePrivate({ data, pageContext }) {
  const pageNode = data.allMarkdownRemark.edges[0].node;
  const { chapterIndex, sectionIndex, previous, next } = pageContext;

  //  console.log("data.allMarkdownRemark.edges[0]", data.allMarkdownRemark.edges[0]);
  const prevElement =
    previous ? (
      <ReadPrev to={previous.slug} text={previous.section.name} />
    ) : <></>
    ;
  const nextElement = next ? (
    <ReadNext to={next.slug} text={next.section.name} />
  ) : <></>;

  // console.log(pageNode)

  return (
    <Layout>
      <Container fluid >
        <Row>
          <Col sm={2} id="side-nav" style={{ paddingRight: 0, paddingLeft: 0 }}>
            <Chapters chapterIndex={chapterIndex} sectionIndex={sectionIndex} />
          </Col>
          <Col sm={10} className="book">
            {doDebug && <span className="debug" >{chapterIndex} - {sectionIndex} {pageNode.fields.section.name} </span>}
            <Container fluid id="content-wrapper">
              <Row>
                <Col xd={12}>
                  <main dangerouslySetInnerHTML={{ __html: pageNode.html }} />
                </Col>
              </Row>
              <Row>
                <Col sm={true}>
                  {prevElement}
                </Col>
                <Col sm={true} />
                <Col sm={true}>
                  {nextElement}
                </Col>
              </Row>
            </Container>
          </Col>
          {/* <Col sm={2}>World</Col> */}
        </Row>
      </Container>
    </Layout >
  )
  //            <h1>{pageNode.title}</h1>

}
function hasAccessToBook() {
  if (!isAuthenticated()) {
    return false;
  }
  return hasValidPlanSubscription(BOOK_PLAN);
}


export const BookPage = ({ data, pageContext }) =>
  hasAccessToBook()
    ?
    (<BookPagePrivate data={data} pageContext={pageContext} />)
    :
    (<><span>You need to <Link to={`/app/login`}>log in</Link></span></>)
  ;

export default BookPage;

export const query = graphql` 
  query($id: String!) {
    allMarkdownRemark(filter: {  id: { eq: $id }  }) {
      edges {
        next {
          fields {
            chapter {
              index
              name
            }
            section {
              name
            }
            slug
          }
        }
        node {
          id
          frontmatter {
            title
          }
          html
          headings {
            depth 
            value
          }
          tableOfContents
          fields {
            slug
            chapter {
              index
              name
            }
            section {
              index
              name
            }
          }
        }
        previous {
          fields {
            chapter {
              name
            }
            section {
              name
            }
            slug
          }
        }
      }
    }
  }
`

